import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import { MdWork, MdSchool, MdStar, MdCoPresent } from "react-icons/md";
import { IoIosPeople } from "react-icons/io";
import { GiAchievement } from "react-icons/gi";
import { FaMedal } from "react-icons/fa";
import "react-vertical-timeline-component/style.min.css";
import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import MultiSelect from "./MultiSelect";
import ProcoreImg from "../static/images/Procore_Logo_FC_Black_RGB.png";
import SiemensImg from "../static/images/Siemens-Logo-PNG5.png";
import BldImg from "../static/images/bld.svg";

const Timeline = () => {
  const [selected, setSelected] = useState([]);
  return (
    <>
      <div style={{ backgroundColor: "#ecf0f1" }} className="my-1 pt-1">
        <Container>
          <h2
            className="text-center mb-0 mt-4 fw-lighter fs-1"
            style={{ color: "#323838", fontFamily: "calibri" }}
          >
            My Journey
          </h2>
          <Row>
            <Col xl={{ span: 6, offset: 3 }}>
              <hr />
              <p
                class="text-muted fst-italic text-center mb-0"
                style={{ fontSize: "0.9em" }}
              >
                Filter activities by tag
              </p>
              <MultiSelect setSelected={setSelected} />
            </Col>
          </Row>

          <VerticalTimeline
            lineColor="white"
            animate={true}
            className="overflow-hidden"
          >
            {/* SWE Intern Siemens EDA */}
            {(selected.length === 0 || selected.includes("career")) && (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  color: "unset",
                  borderTop: "4px solid #2196F3"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #fff"
                }}
                date="Jul 2023 - Present"
                dateClassName="text-black"
                iconStyle={{ background: "#2196F3", color: "#fff" }}
                icon={<MdWork />}
              >
                <div className="d-flex justify-content-center p-4 mb-3 border rounded">
                  <img
                    alt="procore technologies"
                    src={ProcoreImg}
                    className="mw-100"
                  />
                </div>

                <h3 className="vertical-timeline-element-title">
                  Software Engineer Intern
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  Procore Technologies
                  <br />
                  Cairo, Egypt
                </h4>
              </VerticalTimelineElement>
            )}
            {/* DevOps Intern Siemens EDA */}
            {(selected.length === 0 || selected.includes("career")) && (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  color: "unset",
                  borderTop: "4px solid #2196F3"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #fff"
                }}
                date="Jul 2023 - Aug 2023"
                dateClassName="text-black"
                iconStyle={{ background: "#2196F3", color: "#fff" }}
                icon={<MdWork />}
              >
                <div className="d-flex justify-content-center p-4 mb-3 border rounded">
                  <img
                    alt="procore technologies"
                    src={SiemensImg}
                    className="mw-100"
                  />
                </div>

                <h3 className="vertical-timeline-element-title">
                  DevOps Engineer Intern
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  Siemens EDA
                  <br />
                  Cairo, Egypt
                </h4>
                <ul style={{ paddingLeft: "1rem" }} className="mt-2">
                  <li>
                    Created Docker images for test execution on diverse
                    platforms
                  </li>
                  <li>
                    Enhanced testing consistency by incorporating middleware
                    validations and healthchecks scripts
                  </li>
                  <li>
                    Collaborated in legacy-to-new build system migration,
                    deploying a vital installation and packaging feature
                  </li>
                </ul>
              </VerticalTimelineElement>
            )}
            {/* Software Eng bld.ai */}
            {(selected.length === 0 || selected.includes("career")) && (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  color: "unset",
                  borderTop: "4px solid #2196F3"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #fff"
                }}
                date="Nov 2022 - Present"
                dateClassName="text-black"
                iconStyle={{ background: "#2196F3", color: "#fff" }}
                icon={<MdWork />}
              >
                <div className="d-flex justify-content-center p-4 mb-3 border rounded">
                  <img
                    alt="procore technologies"
                    src={BldImg}
                    className="mw-100"
                  />
                </div>

                <h3 className="vertical-timeline-element-title">
                  Software Engineer
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  BLD.ai
                  <br />
                  Boca Raton, Florida (Remote)
                </h4>
                <ul style={{ paddingLeft: "1rem" }} className="mt-2">
                  <li>Architect, build, and maintain full-stack systems.</li>
                  <li>
                    Develop web3 dApps to integrate applications with the
                    blockchain
                  </li>
                </ul>
              </VerticalTimelineElement>
            )}
            {/* ethdenver */}
            {(selected.length === 0 || selected.includes("competitions")) && (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  color: "unset",
                  borderTop: "4px solid #1abc9c"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #fff"
                }}
                date="March 2023"
                dateClassName="text-black"
                iconStyle={{ background: "#1abc9c", color: "#fff" }}
                icon={<FaMedal />}
              >
                <h3 className="vertical-timeline-element-title">
                  Participant (unofficial)
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  EthDenver Hackathon
                </h4>
                <ul style={{ paddingLeft: "1rem" }} className="mt-2">
                  <li>
                    Created a web3 dApp that awards problem solvers and code
                    contributors.
                  </li>
                  <li>
                    Won the first place for one of the main prizes: Summon -
                    Build a Quest by Game7
                  </li>
                </ul>
              </VerticalTimelineElement>
            )}
            {/* good hack */}
            {(selected.length === 0 || selected.includes("competitions")) && (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  color: "unset",
                  borderTop: "4px solid #1abc9c"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #fff"
                }}
                date="February 2023"
                dateClassName="text-black"
                iconStyle={{ background: "#1abc9c", color: "#fff" }}
                icon={<FaMedal />}
              >
                <h3 className="vertical-timeline-element-title">Participant</h3>
                <h4 className="vertical-timeline-element-subtitle">
                  GoodHack Hackthon
                </h4>
                <ul style={{ paddingLeft: "1rem" }} className="mt-2">
                  <li>
                    Created a web3 dApp (Openstack) that resembles decentralized
                    code contribution and code collaboration.
                  </li>
                  <li>
                    Won the Best Online Project - Public Good Bounty by Mantle
                    network.
                  </li>
                </ul>
              </VerticalTimelineElement>
            )}
            {/* Software intern bld.ai */}
            {(selected.length === 0 || selected.includes("career")) && (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  color: "unset",
                  borderTop: "4px solid #2196F3"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #fff"
                }}
                date="Summer 2022"
                dateClassName="text-black"
                iconStyle={{ background: "#2196F3", color: "#fff" }}
                icon={<MdWork />}
              >
                <div className="d-flex justify-content-center p-4 mb-3 border rounded">
                  <img
                    alt="procore technologies"
                    src={BldImg}
                    className="mw-100"
                  />
                </div>
                <h3 className="vertical-timeline-element-title">
                  Software Engineer Intern
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  BLD.ai
                  <br />
                  Boca Raton, Florida (Remote)
                </h4>
                <ul style={{ paddingLeft: "1rem" }} className="mt-2">
                  <li>Build full-stack web applications.</li>
                  <li>
                    Use bootstrap, React.js, and Django as a technology stack.
                  </li>
                </ul>
              </VerticalTimelineElement>
            )}
            {/* hackzurich */}
            {(selected.length === 0 || selected.includes("competitions")) && (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  color: "unset",
                  borderTop: "4px solid #1abc9c"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #fff"
                }}
                date="Sep 2021"
                dateClassName="text-black"
                iconStyle={{ background: "#1abc9c", color: "#fff" }}
                icon={<FaMedal />}
              >
                <h3 className="vertical-timeline-element-title">Participant</h3>
                <h4 className="vertical-timeline-element-subtitle">
                  HackZurich
                </h4>
                <p>
                  Created InVoke app that allows people to quickly communicate
                  disasters or ask for emergencies nearby.
                </p>
              </VerticalTimelineElement>
            )}
            {/* ain shams */}
            {(selected.length === 0 || selected.includes("education")) && (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  color: "unset",
                  borderTop: "4px solid #E91E63"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #fff"
                }}
                date="July 2019 - June 2020"
                dateClassName="text-black"
                iconStyle={{ background: "#E91E63", color: "#fff" }}
                icon={<MdSchool />}
              >
                <h3 className="vertical-timeline-element-title">
                  Computer Engineering | Ain Shams University
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  Cairo, Egypt
                </h4>
                <ul style={{ paddingLeft: "1rem" }} className="mt-2">
                  <li>Freshmen year section coordinator</li>
                  <li>GPA: 3.84</li>
                </ul>
              </VerticalTimelineElement>
            )}
            {/* software eng intern inno */}
            {(selected.length === 0 || selected.includes("career")) && (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  color: "unset",
                  borderTop: "4px solid #2196F3"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #fff"
                }}
                date="Summer 2020"
                dateClassName="text-black"
                iconStyle={{ background: "#2196F3", color: "#fff" }}
                icon={<MdWork />}
              >
                <h3 className="vertical-timeline-element-title">
                  Software Engineer Intern
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  Innopolis University
                  <br />
                  Tatarstan Russia
                </h4>
                <ul style={{ paddingLeft: "1rem" }} className="mt-2">
                  <li>Develop a user interface for Board Games using React.</li>
                  <li>
                    Completed the full UI and the full Django backend in one
                    month.
                  </li>
                </ul>
              </VerticalTimelineElement>
            )}
            {/* software research intern inno */}
            {(selected.length === 0 || selected.includes("career")) && (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  color: "unset",
                  borderTop: "4px solid #2196F3"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #fff"
                }}
                date="Summer 2020"
                dateClassName="text-black"
                iconStyle={{ background: "#2196F3", color: "#fff" }}
                icon={<MdWork />}
              >
                <h3 className="vertical-timeline-element-title">
                  Software Research Intern
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  Innopolis University
                  <br />
                  Tatarstan Russia
                </h4>
                <ul style={{ paddingLeft: "1rem" }} className="mt-2">
                  <li>Conduct research on plagiarism detection techniques.</li>
                  <li>
                    Leverage Java Unit Testing using JUnit Framework to validate
                    students' submissions.
                  </li>
                  <li>Complete Project Documentation.</li>
                </ul>
              </VerticalTimelineElement>
            )}
            {/* inno */}
            {(selected.length === 0 || selected.includes("education")) && (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  color: "unset",
                  borderTop: "4px solid #E91E63"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #fff"
                }}
                date="July 2019 - June 2020"
                dateClassName="text-black"
                iconStyle={{ background: "#E91E63", color: "#fff" }}
                icon={<MdSchool />}
              >
                <h3 className="vertical-timeline-element-title">
                  Innopolis University
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  Tatarstan, Russia
                </h4>
                <ul style={{ paddingLeft: "1rem" }} className="mt-2">
                  <li>Completed 2 summer internships.</li>
                  <li>GPA: 3.5</li>
                </ul>
              </VerticalTimelineElement>
            )}
            {/* local hack day */}
            {(selected.length === 0 || selected.includes("exhibition")) && (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  color: "unset",
                  borderTop: "4px solid #9b59b6"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #fff"
                }}
                date="December 2018"
                dateClassName="text-black"
                iconStyle={{ background: "#9b59b6", color: "#fff" }}
                icon={<MdCoPresent />}
              >
                <h3 className="vertical-timeline-element-title">
                  Vice content manager, Program Presenter, and Robotics
                  Instructor
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  STEMLocalHackDay
                </h4>
                <ul style={{ paddingLeft: "1rem" }} className="mt-2">
                  <li>
                    Contributed to the content and the plan of the LocalHackDay.
                  </li>
                  <li>Delivered a presentation on IOT</li>
                  <li>
                    Held a workshop to instruct participants on how to create a
                    basic 4WD robot.
                  </li>
                </ul>
              </VerticalTimelineElement>
            )}
            {/* cairo water week */}
            {(selected.length === 0 || selected.includes("exhibition")) && (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  color: "unset",
                  borderTop: "4px solid #9b59b6"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #fff"
                }}
                date="October 2018"
                dateClassName="text-black"
                iconStyle={{ background: "#9b59b6", color: "#fff" }}
                icon={<MdCoPresent />}
              >
                <h3 className="vertical-timeline-element-title">Exhibitor</h3>
                <h4 className="vertical-timeline-element-subtitle">
                  1st Cairo Water Week
                </h4>
                <p>
                  Presented my projects and my school's projects to ministers
                  and representatives all over the globe.
                </p>
              </VerticalTimelineElement>
            )}
            {/* wro */}
            {(selected.length === 0 || selected.includes("competitions")) && (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  color: "unset",
                  borderTop: "4px solid #1abc9c"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #fff"
                }}
                date="Aug 2018 - Sep 2018"
                dateClassName="text-black"
                iconStyle={{ background: "#1abc9c", color: "#fff" }}
                icon={<FaMedal />}
              >
                <h3 className="vertical-timeline-element-title">Contestant</h3>
                <h4 className="vertical-timeline-element-subtitle">
                  WRO (World Robotic Olympiad) Egypt
                </h4>
                <ul style={{ paddingLeft: "1rem" }} className="mt-2">
                  <li>Won the bronze medal for the open category.</li>
                  <li>Nominated to WRO World Finals.</li>
                </ul>
              </VerticalTimelineElement>
            )}
            {/* rcj */}
            {(selected.length === 0 || selected.includes("competitions")) && (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  color: "unset",
                  borderTop: "4px solid #1abc9c"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #fff"
                }}
                date="March 2018"
                dateClassName="text-black"
                iconStyle={{ background: "#1abc9c", color: "#fff" }}
                icon={<GiAchievement />}
              >
                <h3 className="vertical-timeline-element-title">Contestant</h3>
                <h4 className="vertical-timeline-element-subtitle">
                  RoboCupJuniors Egypt
                </h4>
                <p>Won the best programming award for our maze solver robot.</p>
              </VerticalTimelineElement>
            )}
            {/* fablab */}
            {(selected.length === 0 || selected.includes("volunteering")) && (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  color: "unset",
                  borderTop: "4px solid #10CC52"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #fff"
                }}
                date="Sep 2017 – Jun 2019"
                dateClassName="text-black"
                iconStyle={{ background: "#10CC52", color: "#fff" }}
                icon={<IoIosPeople />}
              >
                <h3 className="vertical-timeline-element-title">
                  FabLab Supervisor
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  STEM High School
                </h4>
                <ul style={{ paddingLeft: "1rem" }} className="mt-2">
                  <li>Coordinated the Fabrication Laboratory.</li>
                  <li>
                    Utilized FabLab resources by minizing and reusing waste.
                  </li>
                  <li>
                    Helped students prototype their projects using various
                    FabLab cnc machines such as:
                    <ul style={{ paddingLeft: "1rem" }} className="mt-2">
                      <li>Laser Cutter</li>
                      <li>3D printer</li>
                      <li>PCB milling machine</li>
                      <li>CNC woodworking machine</li>
                    </ul>
                  </li>
                </ul>
              </VerticalTimelineElement>
            )}
            {/* ieee */}
            {(selected.length === 0 || selected.includes("career")) && (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  color: "unset",
                  borderTop: "4px solid #2196F3"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #fff"
                }}
                date="Jun 2017 - Aug 2018"
                dateClassName="text-black"
                iconStyle={{ background: "#2196F3", color: "#fff" }}
                icon={<MdWork />}
              >
                <h3 className="vertical-timeline-element-title">
                  IT Moderator | Frontend
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  IEEE CUSB
                </h4>
                <ul style={{ paddingLeft: "1rem" }} className="mt-2">
                  <li>
                    Developed and maintained a user interface for IEEE CUSB
                    website using Angular.
                  </li>
                  <li>
                    Completed the UI in one month and kept it maintained for 9
                    months.
                  </li>
                  <li>Nominated to IT Unit Head</li>
                </ul>
              </VerticalTimelineElement>
            )}
            {/* tachyons */}
            {(selected.length === 0 || selected.includes("volunteering")) && (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  color: "unset",
                  borderTop: "4px solid #10CC52"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #fff"
                }}
                date="Jun 2017 - Oct 2017"
                dateClassName="text-black"
                iconStyle={{ background: "#10CC52", color: "#fff" }}
                icon={<IoIosPeople />}
              >
                <h3 className="vertical-timeline-element-title">
                  Electronics and Robotics Committee Director
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  Tachyons Organization
                </h4>
                <p>
                  Instructed high school students, and guided them in robotics
                  competitions.
                </p>
              </VerticalTimelineElement>
            )}
            {/* it unit */}
            {(selected.length === 0 || selected.includes("volunteering")) && (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  color: "unset",
                  borderTop: "4px solid #10CC52"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #fff"
                }}
                date="Jun 2017 - Oct 2017"
                dateClassName="text-black"
                iconStyle={{ background: "#10CC52", color: "#fff" }}
                icon={<IoIosPeople />}
              >
                <h3 className="vertical-timeline-element-title">
                  IT Unit Director
                </h3>
                <h4 className="vertical-timeline-element-subtitle">
                  STEM High School
                </h4>
                <ul style={{ paddingLeft: "1rem" }} className="mt-2">
                  <li>
                    Cooperate with other unit heads to maintain school's network
                    stability.
                  </li>
                  <li>Help students in technical issues.</li>
                  <li>
                    Found IT Web Development Committee to teach students web
                    development.
                  </li>
                </ul>
              </VerticalTimelineElement>
            )}
            {/* stem */}
            {(selected.length === 0 || selected.includes("education")) && (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{
                  color: "unset",
                  borderTop: "4px solid #E91E63"
                }}
                contentArrowStyle={{
                  borderRight: "7px solid  #fff"
                }}
                date="Sep 2016 - Jun 2019"
                dateClassName="text-black"
                iconStyle={{ background: "#E91E63", color: "#fff" }}
                icon={<MdSchool />}
              >
                {/* <h3 className="vertical-timeline-element-title">
                High School Diploma
              </h3> */}
                <h4 className="vertical-timeline-element-subtitle">
                  STEM High School for Boys-6 October
                  <br />
                  Giza, Egypt
                </h4>
                <p>
                  Completed 5 capstone projects, including 3 career-related
                  projects.
                </p>
                <h4 className="vertical-timeline-element-subtitle my-3">
                  Activites
                </h4>
                <ul
                  style={{
                    paddingLeft: "1rem"
                  }}
                >
                  <li>
                    <figure>
                      <blockquote class="blockquote">
                        <p>IT Unit Director</p>
                      </blockquote>
                      <figcaption class="blockquote-footer">
                        (Oct 2016 – Jun 2017)
                      </figcaption>
                    </figure>
                  </li>
                  <li>
                    <figure>
                      <blockquote class="blockquote">
                        <p>
                          Vice content manager, Program Presenter, and Robotics
                          Instructor at <strong>STEMLocalHackDay</strong>
                        </p>
                      </blockquote>
                      <figcaption class="blockquote-footer">
                        (Dec 2018)
                      </figcaption>
                    </figure>
                  </li>
                  <li>
                    <figure>
                      <blockquote class="blockquote">
                        <p>FabLab Supervisor</p>
                      </blockquote>
                      <figcaption class="blockquote-footer">
                        (Sep 2017 – Jun 2019)
                      </figcaption>
                    </figure>
                  </li>
                </ul>
              </VerticalTimelineElement>
            )}
            <VerticalTimelineElement
              iconStyle={{ background: "#9b59b6", color: "#fff" }}
              icon={<MdStar />}
            />
          </VerticalTimeline>
        </Container>
      </div>
    </>
  );
};

export default Timeline;
